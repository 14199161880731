@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600&display=swap');

body{
  margin:0px;
  padding: 0px;
  
  background-color: #ffffff;
  font-family: Inter, sans-serif;
}
*{
  box-sizing: border-box;
}
a{
  text-decoration: none;
}
button, input{
  outline: none;
  border: none;
}