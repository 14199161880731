@import "../util";

.Partners{
    padding: 35px 0px;
    @include windowWidth();
    h1{
        text-align: center;
        font-size: 42px;
        font-weight: 700;
        line-height: 52px;
        color: rgba(56, 32, 20, 1);
    }
    .PartnerContainer{
        margin-top: 66px;
        display: grid;
        justify-content: space-between;
        grid-template-columns: repeat(1, 1fr);
        grid-column-gap: 50px;
        grid-row-gap: 20px;
        div, img{
            width: 100%;
        }
        @media (min-width: 768px) {
            grid-template-columns: repeat(2, 1fr);
            grid-template-rows: repeat(3, 1fr);
        }
        @media (min-width: 960px) {
            grid-template-columns: repeat(3, 1fr);
            grid-template-rows: repeat(2, 1fr);
        }
    }
}