@import "../util";

.Contact{
    @include flexCenter($justifyContent: space-between);
    @include windowWidth();
    align-items: flex-end;
    color: $textFontColor;
    margin-top: 100px;
    margin-bottom: 56px;

    .Contact__info{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }
    .Contact__logo{
        img{
            height: 32px;
            margin-bottom: 55px;
        }
    }
    p{
        margin: 0px;
    }
    
    .Contact__email{
        margin-top: 21px;
    }
}
.SocialMedia{
    margin: 0px 10px;
    img{
        width: 24px;
    }
}
@media(max-width:768px){
    .Contact{
        flex-direction: column;
        align-items: center;
    }
    .Contact__socialMedia{
        margin-top: 20px;
    }
}