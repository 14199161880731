@import "../util";

.modal-90w{
    max-width: 1440px !important;
    width: 90% !important;
}
.BusinessPartnerModal{
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding: 50px 55px;
    margin:auto;
    color: $headerFontColor;
    .Modal__img{
        margin-right: 20px;
        width: 50%;
        img{
            width: 100%;
        }
    }
}
@media(max-width:960px){
    .Modal__img{
        display: none;
    }
    .Modal__form{
        width: 100% !important;
    }
}