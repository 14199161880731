@import "../util";

.FeatureBox{
    background-color: #ffffff;
    width:300px;
    height: 330px;
    margin: 20px;
    border-radius: 10px;
    overflow: hidden;
    position: relative;
    .FeatureBox__img{
        text-align: center;
        img{
            height: 180px;
            object-fit: contain;
        }
    }
    .FeatureBox__text{
        margin-top: 32px;
        background-color:#ffffff;
        @include flexCenter($flexDirection: column);
        text-align: center;
        h2{
            margin: 0px;
            font-size: 24px;
            font-style: normal;
            font-weight: 600;
            line-height: 32px;
            letter-spacing: 0px;
            color: $headerFontColor
        }
        p{
            margin-top: 16px;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 26px;
            letter-spacing: 0px;
            text-align: center;
            color: $textFontColor;
        }
    }
}
@media(max-width:1190px){
    .FeatureBox{
        flex-grow: 1;
    }
    .FeatureBox__img img{
        object-fit:contain;
    }
}