@import "../util";

#main{
    width:100%;
    margin: auto;
    height:768px;
    position: relative;
}
.name{
    display: flex;
    align-items: center;
    @include windowWidth();
    margin-top: 20vh;
    margin-bottom: 15vh;
    .details{
        color: $textFontColor;
    }
    .main__text{
        margin: 20px 40px 20px 00px;
        h6::before{
            background-color: #000;
            content: "";
            display: inline-block;
            height: 3px;
            position: relative;
            vertical-align: middle;
            width: 24px;
            margin-right: 8px;
        }
    }
    h1{
        font-size: 58px;
        font-style: normal;
        font-weight: 700;
        line-height: 70px;
        letter-spacing: -1px;
        text-align: left;
        color:$headerFontColor;
        margin: 40px 0px;
    }
    h6{
        margin: 20px 0px;
        font-size: 24px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px;
        letter-spacing: -1px;
        text-align: left;
    }
    p{
        margin: 40px 0px;
    }
}
.main__img{
    width:370px;
    img{
        width: 100%;
        object-fit: contain;
    }
}
.store-btns{
    display: flex;
}
.store-btn{
    opacity: 0.5;
    width: 180px;
    height: 50px;
    @include flexCenter();
    background: $buttonBackground;
    margin: 3px 20px 0px 0px;
    padding: 12px 22px;
    border-radius: 60px;
    box-shadow: 5px 10px 30px rgba(242, 69, 22, 0.157);;
    color:#ffffff;
    text-decoration: none;
    &.live {
        opacity: 1;
    }
    &:hover{
        background-color: $primaryTheme;
        transition: all ease 0.5s;
        color: #ffffff;
    }
    .store-btn-content{
        display: flex;
        align-items: center;
        
    }
    .store-img{
        margin-right: 10px;
    }
    .action-btn{
        font-size: 9px;
    }
}
@media(max-width:959px){
    .name{
        text-align: center;
    }
    .store-btn{
        margin: auto;
        margin-bottom: 10px;
    }
}

@media(max-width:970px){
    .name{
        margin: 20% 10%;
        h1, h6{
            text-align: center;
        }
        .main__text{
            margin: 20px;
        }
    }
    .main__img{
        display: none;
    }
}
@media(max-width:600px){
    .store-btns{
        flex-direction: column;
    }
}