@import "../util";

.Features{
    @include flexCenter($flexDirection: column);
    @include windowWidth();
    height:60vh;
    box-sizing: border-box;
    padding-top: 40px ;
    background-color: #ffffff;
}

.FeaturesContainer{
    @include flexCenter($justifyContent:space-between);
}

@media(max-width:1190px){
    .Features{
        height:auto;
    }
    .FeaturesContainer{
        flex-wrap: wrap;
    }
}