@import "../util";

.logo img{
    width: 200px;
}
nav.active{
    box-shadow: $boxShadow;
}
nav{
    background-color: #ffffff;
    div{
        @include flexCenter($justifyContent: space-between);
        width: 90%;
        margin: auto;
        max-width: 1200px;
    }
    padding-top: 40px;
    padding-bottom: 20px;
    position: fixed;
    left: 0;
    top: 0;
    width:100%;
    margin: auto;
    z-index: 1;
    transition: all ease 0.2s;
    ul{
        padding: 0px;
        list-style: none;
        display: flex;
        margin-bottom: 0px;
        li a, .model__link{
            cursor: pointer;
            text-decoration: none;
            white-space: nowrap;
            font-size: 18px;
            font-weight: 500;
            line-height: 26px;
            letter-spacing: 0px;
            text-align: left;

            height:40px;
            margin: 3px;
            padding: 7px 10px;
            display: flex;
            color:$headerFontColor;
            letter-spacing: 1px;
            transition: all ease 0.2s;
        }
        .general:hover, .model__link:hover{
            text-decoration: underline;
            color: $headerFontColor;
            -webkit-text-decoration-color:#F6671E;
            text-decoration-color: #F6671E;
            -webkit-text-decoration-thickness:2px;
            text-decoration-thickness:3px;
            text-underline-offset: 5px;
        }
        .special{
            border: 1px solid #382014;
            padding: 7px 17px;
            border-radius: 60px;
            &:hover{
                color: $headerFontColor;
                background-color: rgba(153, 151, 148, 0.16);
            }
        }
    }
}
.active{
    box-shadow: $boxShadow;
    transition: all ease 0.2s;
}
nav .menu-btn, .menu-icon{
    display:none;
}
@media(max-width:1200px){
    .menu-icon{
        display:block;
    }
    nav{
        justify-content: space-between;
        padding-top: 40px;
        height: 105px;
        .menu{
            display:block;
            position:absolute;
            top:105px;
            left:-100%;
            transition: all ease 0.2s;
            background-color:#FFFFFF;
            border-bottom:4px solid $primaryTheme;
            width:100%;
            padding-bottom: 10px;
            margin: 0px;
            li a, .model__link{
                text-align: left;
                width: $windowWidthPct;
                margin: auto;
                align-items: center;
                padding: 0px;
                &.special{
                    margin-left: 10%;
                    padding: 7px 25px;
                    width: 200px;
                }
            }
        }
        .menu-icon{
            cursor:pointer;
            float:right;
            padding:28px 0px 28px 20px;
            position:relative;
            user-select: none;
        }
    }
    nav .menu-icon .nav-icon{
        background-color:#333333;
        display:block;
        height:2px;
        position:relative;
        transition: background 0.2s ease-out;
        width:18px;
        &::before, &::after{
            background:#333;
            content:'';
            display:block;
            height:100%;
            position:absolute;
            transition: all ease 0.2s;
            width:100%;
        }
        &::before{
            top:5px;
        }
        &::after{
            top:-5px;
        }
    }
    nav .menu-btn:checked ~ .menu-icon .nav-icon{
        background:transparent;
        &::before{
            transform: rotate(-45deg);
            top:0;
        }
        &::after{
            transform: rotate(45deg);
            top:0;
        }
    }
    nav .menu-btn:checked ~ .menu{
        display:block;
        left: 0px;
        transition: all ease 0.2s;
    }
}