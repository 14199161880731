@import "../util";

.modal-90w{
    max-width: 1440px !important;
    width: 90% !important;
}
.ContentCreatorModal{
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding: 50px 55px;
    margin:auto;
    color: $headerFontColor;
    .Modal__img{
        margin-right: 20px;
        width: 40%;
        img{
            width: 100%;
        }
    }
}
.Modal__form{
    width: 464px;
}
h2{
    margin-bottom: 56px;
}
.Modal__formRow{
    margin: 16px 0px;
    display: flex;
    justify-content: space-between;
    &>div{
        flex: 1;
        margin-right: 28px;
    }
    .label{
        margin-bottom: 8px;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 26px;
        letter-spacing: 2px;
    }
    .focusInput:focus, input[type=tel]:focus{
        border: 1px solid $headerFontColor;
    }
    .FoodType__Row{
        display: flex;
        align-items: center;
        margin-right: 0;
        width: 100%;
        input[type=checkbox], input[type=radio]{
            margin-right: 16px;
            background-color: rgba(235, 234, 237, 1);
            appearance: none;
            -webkit-appearance: none;
            -moz-appearance: none;
            width: 17px;
            height: 17px;
            cursor: pointer;
            &:checked{
                background-color: #f6671e;
                background: #f6671e url("data:image/gif;base64,R0lGODlhCwAKAIABAP////3cnSH5BAEKAAEALAAAAAALAAoAAAIUjH+AC73WHIsw0UCjglraO20PNhYAOw==") 3px 3px no-repeat;
            }
            &.errorInput{
                background-color: rgba(236, 19, 19, 0.1);
            }
        }
        input[type=checkbox]{
            border-radius: 6px;
        }
        input[type=radio]{
            border-radius: 50%;
        }

        span{
            font-size: 16px;
            font-weight: 500;
            line-height: 26px;
            letter-spacing: 2px;
        }
        .otherInput{
            margin-left: 33px;
            padding: 8px 0px;
            background-color: white;
            border-radius: 0px;
            height: 40px;
            width: 100%;
            border-bottom: 1px solid black;
        }
    }
    button.Modal__submit{
        width: 100%;
        text-align: center !important;
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: 26px;
        letter-spacing: 0px;
        text-align: left;

        background: $buttonBackground;
        white-space: nowrap;
        margin-top: 10px;
        margin-left: auto;
        margin-right: 28px;
        padding: 12px 22px;
        height: 50px;
        border-radius: 60px;
        border: none;
        outline: none;
        color: #ffffff;
        background-color:$primaryTheme;
        cursor: pointer;
        &:hover{
            background-color: $secondaryTheme;
            transition: all ease 0.3s;
            color: #ffffff;
        }
    }
    .SocialMediaRow{
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-radius: 6px;
        padding: 0px 3px;
        &:hover{
            background: rgba(235, 234, 237, 1);
        }
        img{
            cursor: pointer;
            width: 20px;
            height: 20px;
        }
    }
    input.errorInput, .errorPhoneInput > input{
        background-color: rgba(236, 19, 19, 0.1);
        border: 1px solid #EC1313;;
    }
}
.double{
    &>div{
        flex:0.5;
    }
}
input[type=text], input[type=tel]{
    padding: 8px 16px;
    background: rgba(235, 234, 237, 1);
    height: 50px;
    border-radius: 8px;
    width: 100%;
}
.buttonText{
    margin-top: 16px;
    padding: 0;
    color: rgba(246, 103, 30, 1);
    background-color: white;
}
.error{
    height: 0;
    opacity: 0;
    margin-top: 10px;
    color: rgba(236, 19, 19, 1);
    font-family: SF Pro Text;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px;
    // transition: opacity 0.1s ease-out;
    &.active{
        height: 18px;
        opacity: 1;
        box-shadow: none;
    }
}
.Modal__success{
    display: flex;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    img{
        width: 60%;
        margin: auto;
    }
    h2{
        font-size: 48px;
        font-style: normal;
        font-weight: 700;
        line-height: 40px;
        margin-bottom: 40px;
    }
    p{
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: 40px;

    }
    p.boldText{
        font-size: 28px;
        font-style: normal;
        font-weight: 700;
        line-height: 40px;
    }
}
.CloseModal{
    width: 40px;
    height: 40px;
    margin-left: auto;
    cursor: pointer;
}
@media(max-width:960px){
    .Modal__img{
        display: none;
    }
    .Modal__form{
        width: 100% !important;
    }
}