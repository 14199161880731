$primaryTheme: rgba(242, 69, 22, 1);
$secondaryTheme: rgba(246, 110, 38, 1);
$buttonBackground: linear-gradient(360deg, #F24516 6.86%, #F66E26 90.2%);
$buttonHoverBackground: linear-gradient(360deg, #DE3304 6.86%, #EB5C10 90.2%);
$boxShadow: 5px 10px 30px rgba(242, 69, 22, 0.157);
$headerFontColor: rgba(56, 32, 20, 1);
$textFontColor: rgba(120, 116, 115, 1);
$windowWidthPct: 80%;

@mixin flexCenter($flexDirection: row, $justifyContent: center){
    display: flex;
    align-items: center;
    flex-direction: $flexDirection;
    justify-content: $justifyContent;
}
@mixin windowWidth {
    width: $windowWidthPct;
    margin: auto;
    max-width: 1200px;
}