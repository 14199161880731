@import "../util";

.About{
    @include flexCenter($justifyContent: space-between);
    @include windowWidth();
    height: 600px;
}
  
.About__text{
    width: 50%;
    list-style: circle;
    h2{
        font-size: 42px;
        font-weight: 700;
        line-height: 52px;
        color: $headerFontColor;
    }
    ul{
        padding: 0px 20px;
        font-size: 22px;
        font-style: normal;
        font-weight: 500;
        line-height: 32px;
        color: $textFontColor;
    }
    button{
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: 26px;
        letter-spacing: 0px;
        text-align: left;

        background: $buttonBackground;
        white-space: nowrap;
        margin-top: 20px;
        padding: 12px 22px;
        height: 50px;
        border-radius: 60px;
        border: none;
        outline: none;
        color: #ffffff;
        cursor: pointer;
        &:hover{
            background: $buttonHoverBackground;
        }
    }
}
.About__img{
    width: 45%;
    img{
        object-fit: contain;
        width: 100%;
        max-width: 400px;
    }
}
  
@media(max-width:768px){
    .About{
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding-top: 0px;
        text-align: center;
        margin-top: 20px;
        ul{
            padding: 0px;
            list-style: none;
        }
    }
    .About__img{
        width: 60%;
        margin-bottom: 10px;
    }
    .About__text{
        width: 90%;
        h2{
            font-size:24px;
        }
        ul{
            font-size:16px;
            width: 100%;
            line-height: 20px;
        }
    }
}
  